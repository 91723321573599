import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import s from "./aboutUs.module.css";

const AboutUs = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	console.log("history", history);
	return (
		<div className={s.aboutUs}>
			<div className="container">
				<div className="row">
					<h3>{t("aboutUs")}</h3>
				</div>
			</div>
			<div>
				<div className="row">
					<div className={s.container_wrap}>
						{/* <div className={s.delivery_title}></div> */}
						<div className={s.delivery_content} style={{ minHeight: 300 }}>
							<p>
								SunSushi – це завжди якісно, швидко та смачно! SunSushi
								приготують для вас ваші улюблені страви, як, і суші традиційної
								японської кухні, так і, суші за оригінальною рецептурою!
							</p>
							<p>
								Виготовлені з продуктів найвищої якості, страви SunSushi
								передадуть вам справжній смак і задоволення.
							</p>
							<p>
								Концепція SunSushi полягає, не тільки в приготуванні суші, ми
								постійно оновлюємо та розширюємо меню, для замовлення суші,
								піци, ВОК, алкогольних та безалкогольних напоїв в одному місці!
							</p>
							<div
								className={s.link}
								onClick={() => history.push("/privacy-policy")}
							>
								Політика конфіденційонсті
							</div>
							<div
								className={s.link}
								onClick={() => history.push("/privacy-policy-en")}
							>
								Privacy policy
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
