import React from 'react';
import { useSelector } from 'react-redux';
import {NavLink} from 'react-router-dom';

import s from './header.module.css';
import logo from '../../../assets/logo.jpg';

import { useTranslation } from "react-i18next";
import menu from '../../../assets/burger.svg';
import close from '../../../assets/close.svg';


const Header = (props) => {
    const { t, i18n } = useTranslation();
    const cityId = useSelector(state => state.menu.activeCity.id);
    const cities = useSelector(state => state.menu.data ? state.menu.data.settings.City : null);
    const city = cities ? cities.find(t => t.spot_id === cityId) : null;
    const state = useSelector(state => state);

    console.log('state', state);
    

    let begin = '00:00';
    let end = '00:00';

    console.log('city ***', cities);
    if (city) {
        begin = city.workingHours[0].begin;
        end = city.workingHours[0].end;
    }

    return(
        <header className={s.header}>
            <div className="row">
                <div className={s.header_nav}>
                        <div className={s.logo}>
                            <NavLink to="/"><img src={logo} alt="logo" /></NavLink>
                           {!props.mobileMenu && <div onClick={props.onMobileMenu} className={s.burgerMenu}><img src={menu} /></div>}
                            {props.mobileMenu && <div onClick={props.closeMobileMenu} className={s.burgerMenuClose}><img src={close} /></div>}
                        </div>
                        <div className={s.nav}>
                            <NavLink to="/menu/">{t('menu')}</NavLink>
                            <NavLink to="/delivery">{t('delivery')}</NavLink>
                            <NavLink to="/about-us">{t('aboutUs')}</NavLink>
                        </div>
                        <div className={s.header_info}>
                            {/* <div className={s.language} onClick={() => props.openLanguage()}>
                                <span>{props.activeCity.name}</span>
                            </div> */}
                            <div className={s.info_container}>
                                {/* <a  href="tel:+380962109095" className={`binct-phone-number-2 ${s.phone} ${s.header_icon}`}></a> */}
                                <div onClick={props.onMobileNumbers} className={`binct-phone-number-2 ${s.phone} ${s.header_icon}`}></div>
                                <div className={s.info}>
                                    <a href="tel:+380962109095" className="phone binct-phone-number-1">096 210 90 95</a><br />
                                    <a href="tel:+380509555249" className="phone binct-phone-number-1">050 955 52 49</a>
                                    <span className={s.time}>{begin} - {end}</span>
                                </div>
                            </div>
                            <NavLink to={'/basket'} className={s.basket}>
                                <span>{props.basketCount}</span>
                            </NavLink>
                        </div>
                    </div>
            </div>
        </header>
    )
}

export default Header;