import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter, Redirect } from "react-router";
import {updateProduct, updateModifire, removeModifire, updateGroupModifire,
    removeGroupModifire} from '../../redux/menu';

import Product from './product';

class ProductContainer extends Component{

    componentDidMount() {
        window.scroll(0, 0);
    }

    render(){
        let product = this.props.products.find(item => item['id'] === this.props.match.params.id);
        return(
           product ?  <Product item={product} languageMenu={this.props.languageMenu} 
            updateProduct={this.props.updateProduct} updateModifire={this.props.updateModifire} 
            removeModifire={this.props.removeModifire} updateGroupModifire={this.props.updateGroupModifire}
            removeGroupModifire={this.props.removeGroupModifire}/> :
                <Redirect to='/'/> 
        )
    }
}

const matStateToProps = (state) => {
    return {
        products: state.menu.products,
        categories: state.menu.categories,
        basket: state.menu.basket,
        languageMenu: state.menu.languageMenu
    }
}

export default connect(matStateToProps, {updateProduct, updateModifire, 
    removeModifire, updateGroupModifire, removeGroupModifire})(withRouter(ProductContainer));