import * as axios from "axios";

let testUrl = 'https://api.e-admin.com.ua/sunsushi/';

const instance = axios.create({
    baseURL: testUrl,
    crossDomain: true
});

export const actions = {
    getMenu(){
        return instance.get(`getMenu.php`)
        .then (response => {
            return response.data;
        })
    },
    addOrder(data){
        return instance.post(`add_order.php`, {...data})
        .then (response => {
            return response.data;
        })
    },
    getClient(data){
        return instance.post(`getClient.php`, {...data})
        .then (response => {
            return response.data;
        })
    },
    getCity(){
        return instance.get(`getCity.php`)
        .then (response => {
            return response.data;
        })
    },
    sendOrder(data){
        return instance.post(`sendOrder.php`, {order: data})
        .then (response => {
            return response.data;
        })
    },
    saveOrder(data){
        return instance.post(`saveOrder.php`, {data: data})
        .then (response => {
            return response.data;
        })
    },
    getHash(data){
        return instance.post(`hash.php`, {data: data})
        .then (response => {
            return response.data;
        })
    },

  

};