import React from 'react';
import s from './formControls.module.css';
import { useTranslation } from "react-i18next";

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';


export const Time = ({input, meta, ...props}) => {
    var value = input.value;
    if(props.val !== undefined && value.length === 0 && meta.visited === false){
        value = props.val;
    }

    const onChange = (value) =>  {
        input.onChange(value && value.format('HH:mm'));
      }

    return(
    <div className={s.inputWrapper}>
           <TimePicker
    format={'HH:mm'}
    showSecond={false}
    defaultOpenValue={moment()}
    className={s.time}
    onChange={onChange}
    placeholder={props.label}
    hideDisabledOptions
    focusOnOpen
  />
            {meta.touched && (meta.error && <span>{meta.error}</span>)}
    </div>
    )
}

export const Input = ({input, meta, ...props}) => {
    var value = input.value;
    if(props.val !== undefined && value.length === 0 && meta.visited === false){
        value = props.val;
    }
    return(
    <div className={s.inputWrapper}>
            <input {...input} value={value}  placeholder={props.label}/>
            {meta.touched && (meta.error && <span>{meta.error}</span>)}
    </div>
    )
}

export const Textarea = ({input, meta, ...props}) => {
  var value = input.value;
  if(props.val !== undefined && value.length === 0 && meta.visited === false){
      value = props.val;
  }
  return(
  <div className={s.inputWrapper}>
          <label>{props.label}</label>
          <textarea {...input} value={value} {...props} placeholder={props.label}/>
  </div>
  )
}

export const Select = ({input, meta, ...props}) => {
    const { t, i18n } = useTranslation();

    return(
    <div className={s.inputWrapper}>
            <label>{props.label}</label>
            <select {...input}>
            {props.option.map((item, index) => {
                   return(
                    <option key={index} value={item.id}>{t(item.name)}</option>
                   )
               })}
            </select>
            {meta.touched && (meta.error && <span>{meta.error}</span>)}
    </div>
    )
}

export const Radio = ({input, meta, ...props}) => {
    const { t, i18n } = useTranslation();
    return(
    <div className={s.inputRadio}>
            <label>
                <input type="radio" {...input} {...props} value={props.label}
                />
                <span>{props.label}</span>
            </label>
    </div>
    )
}