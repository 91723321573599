import React from "react";

let PrivacyPolicy = (props) => {
	return (
		<div className={"container"}>
			<div className={"row"}>
				<div>
					<h3>Політика конфіденційності</h3>
					<p>
						Ці Правила обробки, зберігання та захисту даних клієнтів (далі
						Правила) визначають порядок поводження з даними користувачів,
						зареєстрованих на сайті{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						та у застосунку (додатку).
					</p>
					<h4>1. Основні положення.</h4>
					<p>
						Погоджуючись з цими Правилами, Користувач дає дозвіл на збір,
						зберігання, використання та розголошення своїх даних, (в т.ч., але
						не обов'язково, персональних даних), відповідно до цих Правил,
						Законом України «Про захист прав споживачів», « Про захист
						персональних даних »,« Про захист інформації в
						інформаційно-телекомунікаційних системах ».
					</p>
					<p>
						Правила дійсні з моменту реєстрації користувача на{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						та у застосунку (додатку).
					</p>
					<h4>2. Збір та використання персональних даних про клієнта.</h4>
					<p>Дані Користувача є інформацією з обмеженим доступом.</p>
					<p>
						Дані Користувача зберігаються на сервері сайту{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>
						, сервері карткової бази і обробляються за допомогою автоматизованої
						системи.
					</p>
					<p>
						Мета обробки даних Користувача: для забезпечення реалізації
						адміністративно-правових відносин, відносин у сфері податкового і
						бухгалтерського обліку, реклами, інших відносин, при яких
						використовуються дані користувачів.
					</p>
					<p>
						На сайті{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						та у застосунку можуть збирати, зберігати і використовувати такі
						дані:
					</p>
					<ul>
						<li>номер мобільного телефону;</li>
						<li>місто;</li>
						<li>
							інші дані, які були надані Користувачем за допомогою реєстрації на
							сайті{" "}
							<a href="https://sunsushi.com.ua/">
								<b>sunsushi.com.ua</b>
							</a>{" "}
							та у застосунку (додатку).
						</li>
					</ul>
					<p>
						Користувач погоджується з тим, що передані їм дані можуть
						використовуватися для:
					</p>
					<ul>
						<li>
							реєстрації користувача на сайті{" "}
							<a href="https://sunsushi.com.ua/">
								<b>sunsushi.com.ua</b>
							</a>{" "}
							та у застосунку (додатку);
						</li>
						<li>
							повідомлення Заклади, в якому бронюється столик, про деталі броні;
						</li>
						<li>надання послуг і клієнтської підтримки на запит клієнта;</li>
						<li>поліпшення власних послуг, змісту сайту та реклами;</li>
						<li>
							повідомлення клієнта про послуги, новини і рекламних пропозиціях
							за допомогою SMS, електронних повідомлень, пошти і т.д.
						</li>
					</ul>
					<h4>3. Розголошення наданих клієнтом, користувачем даних.</h4>
					<p>Дані користувача надаються третім особам в наступних випадках:</p>
					<ul>
						<li>отримано додаткове згоду клієнта;</li>
						<li>
							надання загальної інформації (статистичні дані, узагальнена
							інформація), яка не містить персональну інформацію і яка не
							ідентифікує клієнта індивідуально;
						</li>
						<li>
							надання інформацію правоохоронним та іншим державним органам у
							відповідь на їх офіційний запит, що стосується кримінального
							розслідування або передбачуваної протиправної діяльності.
						</li>
					</ul>{" "}
					<h4>
						4. Видалення наданих даних (в т.ч. персональних даних) і відмова від
						розсилки.
					</h4>
					<p>
						При зміні даних Користувача, зазначених у пункті 2, а також, якщо
						Користувач не хоче більше користуватися послугами сервісу сайту {""}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>
						{""} або застосунку (додатку)., його дані підлягають зміні або
						видалення.
					</p>
					<p>
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						залишає за собою право використовувати дані Користувача з метою, для
						якої ця інформація була зібрана, протягом одного місяця після
						деактивації інформаці.
					</p>
					<h4>5. Захист даних.</h4>
					<p>
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						зобов'язується вживати належних заходів безпеки для захисту від
						незаконного втручання або неправомірного зміни, розголошення або
						знищення даних Користувача.
					</p>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
