import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import s from './product.module.css';
import { useTranslation } from "react-i18next";
import classes from './product.module.css';
import logo from '../../assets/logo.jpg';


const ProductComponent = (props) => {
    const { t, i18n } = useTranslation();
    const cities = useSelector(state => state.menu.cities);
    const activeCity = useSelector(state => state.menu.activeCity);
    const city = cities.find(t => t.id === activeCity.id);

    // const images = [];
    // props.item.images.forEach(el => images.push(el.imageUrl));

    const getSum = () => {
        let sum = 0;
        let price = props.item.spots[0].price;
        sum += +price / 100;             
        return sum;
    }

    return(
        <div className='container'>
            <div className='row'>
                <div className={s.productContainer}>
                   <div className={s.imgContainer}>
                        <div className={s.product_img} 
                            style={{ backgroundImage: `url(${props.item.photo ? props.item.photo.replace(' ', '%20') : logo})` }}
                        />
                    </div>
                    <div className={s.infoContainer}>
                        <div className={s.rowInfo}>
                            <div className={s.title}>
                                {props.item['name'] ? props.item['name'][props.languageMenu] : ''}
                            </div>
                            <div className={s.description}>
                                {props.item['description'] ? props.item['description'][props.languageMenu] : ''}
                            </div>
                            <div className={s.product_footer}>
                            <div className={s.price}>
                                <span>{getSum()}</span> ₴
                            </div>
                            <div className={s.add_container}>
                                {props.item.count > 0 ? <div className={[`${s.count_product} ${s.count}`]}>
                                    <div className={s.minus} onClick={() => props.updateProduct('decrement', props.item['id'])}></div>
                                        <div className={s.result}>{props.item.count}</div>
                                    <div className={s.plus} onClick={() => props.updateProduct('increment', props.item['id'])}></div>
                                </div> :  <div onClick={() => props.updateProduct('increment', props.item['id'])} className={s.add_product}>
                                    <span>{t('add')}</span>
                                 </div>}
                            </div>
                        </div>
                        
                        </div>
                        
                        {/* <div className={s.productCalories}>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.weight * 1000)}</div>Вага, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.fiberFullAmount).toFixed(0)}</div>Білки, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.fatFullAmount)}</div>Жири, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.carbohydrateFullAmount).toFixed(0)}</div>Вуглеводи, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.energyFullAmount).toFixed(0)}</div>Ккал</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withRouter(ProductComponent);