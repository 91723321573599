const GET_FIREBASE = 'GET-FIREBASE';
const CHECK_ORDER_AVAILABLE = 'CHECK-ORDER-AVAILABLE';
const UPDATE_WORKING_HOURS = 'UPDATE-WORKING-HOURS';

const initialValues = {
    data: [],
    workingHours: [],
    orderAvailable: false,
}

const Firebase = (state = initialValues, action) => {
    switch(action.type) {
        case GET_FIREBASE: {
            return {
                ...state,
                data: action.data
            }
        }
        case CHECK_ORDER_AVAILABLE: {
            return {
                ...state,
                orderAvailable: action.orderAvailable
            }
        }
        default:
            return state
    }
}

export const getFirebase = (data) => ({type: GET_FIREBASE, data});
export const checkOrderAvailable = (orderAvailable) => ({type: CHECK_ORDER_AVAILABLE, orderAvailable});
export const updateWorkingHours = (workingHours) => ({type: UPDATE_WORKING_HOURS, workingHours});

export default Firebase;