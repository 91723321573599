import firebase from 'firebase';

var config = {
    apiKey: "AIzaSyAOQ0hif53SxO_wbisenY1ziGVTeUUkriw",
    authDomain: "sunsushi-449fe.firebaseapp.com",
    databaseURL: "https://sunsushi-449fe-default-rtdb.firebaseio.com",
    projectId: "sunsushi-449fe",
    storageBucket: "sunsushi-449fe.appspot.com",
    messagingSenderId: "428979999588",
    appId: "1:428979999588:web:6043712fca62b168636071"
};

firebase.initializeApp(config);

firebase.database();

export default firebase;