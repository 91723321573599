import React from "react";

import s from "./footer.module.css";

import Logo from "../../../assets/logo_footer.png";
import IOS from "../../../assets/ios.png";
import Android from "../../../assets/android.png";
import instagram from "../../../assets/instagram.svg";
import facebook from "../../../assets/facebook.svg";
import tiktok from "../../../assets/tik-tok.svg";
import pay from "../../../assets/pay.png";
import { withRouter, Redirect } from "react-router";

import { useTranslation } from "react-i18next";

const Footer = (props) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={s.footer}>
			<div className="row">
				<div className={s.footer_container}>
					<div className={s.footer_logo}>
						<img src={Logo} alt="" />
					</div>
					<div className={s.fotter_info}>
						<div className={s.footer_item}>
							<div className={s.title}>{t("toOrder")}:</div>
							<a href="tel:+380962109095" className="binct-phone-number-1">
								096 210 90 95
							</a>
							<a href="tel:+380509555249" className="binct-phone-number-1">
								050 955 52 49
							</a>
							{/* <a href="mailto:klaragroup.delivery@gmail.com" className="mail">klaragroup.delivery@gmail.com</a> */}
							<div className={s.terminal}>
								м.Олександрія, вул. Братська (Калініна), 30
							</div>
						</div>
						<div className={s.footer_item}>
							<div className={s.title}>{t("soc")}:</div>
							<div className={s.social}>
								<a
									target="_blank"
									href="https://www.instagram.com/sun_sushi_/?utm_medium=copy_link"
								>
									<img src={instagram} alt="instagram" />
								</a>
								<a target="_blank" href="#">
									<img src={facebook} alt="facebook" />
								</a>
								{/* <a target="_blank" href="https://vm.tiktok.com/ZMeYVnpqn/"><img src={tiktok}/></a> */}
							</div>
						</div>
						<div className={s.footer_item}>
							<div className={s.title}>{t("app")}:</div>
							<a
								target="_blank"
								href="https://apps.apple.com/ua/app/sun-sushi-app/id6450598200?l=ru"
							>
								<img src={IOS} alt="IOS" />
							</a>
							<a
								target="_blank"
								href="https://play.google.com/store/apps/details?id=com.wertnothers.sunsushi"
							>
								<img src={Android} alt="android" />
							</a>
						</div>
						{/* <div className={s.footer_item}>
                        <img className={s.payImg} src={pay} alt="pay" />
                    </div> */}
					</div>
				</div>
				{/* <div className={s.docContainer}>
                <div onClick={() => props.history.push('/privacy-policy')} className={s.doc} target='_blank' href='https://drive.google.com/file/d/1tU07dPKqofSW1f7TWrEitXtu9WtRploX/view?usp=sharing'>Політика конфіденційності</div>
                <div onClick={() => props.history.push('/offer')} className={s.doc} target='_blank' href='https://drive.google.com/file/d/1RDn0bP_EP-7OcnDHSBDV7Ou0jRtXcjVz/view?usp=sharing'>Публічний договір</div>
            </div> */}
			</div>
		</div>
	);
};

export default withRouter(Footer);
