import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkingHours } from '../../redux/firebase';
import i18n from "i18next";

import s from './basket.module.css';
import Button from '../../components/button';
import Product from './product/product';
import {NavLink, useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Basket = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const alertMessage = useSelector(state => state.menu.data.settings);
    const cities = useSelector(state => state.menu.cities);
    const activeCity = useSelector(state => state.menu.activeCity);
    const city = cities.find(t => t.id === activeCity.id);
    let products = props.products;
    
    let result = [];
   
    if(products.length > 0){
        let newProduct  = products.filter(item => item.count > 0);
        newProduct.map( it => {
            let search = result.find(i => i.id === it.id);
            if(!search){
                result.push(it);
            }
        });
    }; 

    const getSum = () => {
        let sum = 0;

        for(let item in result) {  
            let price = result[item].spots[0].price;
            let count = result[item].count;

            // console.log('price #', price, count);

            // if (city && city.terminals) {
            //    city.terminals.map(terminal => {
            //         if (terminal.id === t.terminalId) {
            //             price = t.price;
            //         }
            //     })
            // }

            sum += ((+price*10)*count)/1000;             
        }

        if (sum < 500 && sum !== 0) {
            sum += 50;
        }

        return sum;
    }

    // console.log('Sum', getSum());

    // let sum = result.reduce((a, b) => a+Number(b['price'])*b.count, 0);

    // if (sum < 500 && sum !== 0) {
    //     // const deliveryItem =  { id: "9999", price: 50, name: 'Доставка', differentPricesOn: [], modifiers: [], groupModifiers: [], images: [{imageId: ''}] };
    //     // result = [...result, deliveryItem];
    //     sum += 50;
    // } else {
    //     // result = result.filter(t => t.id !== "9999");
    // }

    console.log('result', result);

    const checkOrderAvailable = (_, orderAvailable) => {
        let message = '';
        if (alertMessage && alertMessage.appSettings) {
            message = alertMessage.appSettings.closedSpotAlertDescription;
        }

        console.log('orderAvailable', orderAvailable);
        console.log('alertMessage', alertMessage);
        console.log('result #', result, orderAvailable);

        if(result.length > 0 && orderAvailable){
            history.push('/checkout');
        } else if (!orderAvailable) {
            alert(message);
        } else {
            alert(t('addToBusket'));
        }
    }

    const openCheckout = () => {
        props.getWorkingHours()
            .then(snapshot => {
                const workingHours = snapshot.val();
                // console.log('workingHours ***', workingHours);
                if (workingHours) {
                    dispatch(updateWorkingHours(workingHours));
                }
            })
            .catch(err => console.error(err));

        props.getFieldOrderAvailable()
            .then(snapshot => {
                const orderAvailable = snapshot.val();
                checkOrderAvailable(props.result, orderAvailable);
            })
            .catch(err => console.error(err));
    }

    // console.log(result, 'result');

    // const openCheckout = () => {
    //     if(result.length > 0){
    //         history.push('/checkout');
    //     } else {
    //         history.push('/');
    //     }
    // }

    const { t, i18n } = useTranslation();
    return(
        <div className='container'>
            <div className='row'>
                <div className={s.basketContainer}>
                    <h3>{t('basket')}</h3>
                    <div className={s.basket}>
                        {result.length === 0 ? <div className={s.warning}>{t('addProducts')}</div> : null}
                        <div className={s.productList}>
                         {result.map((item, index) => {
                             return <Product languageMenu={props.languageMenu} key={index} item={item} updateProduct={props.updateProduct} deleteProduct={props.deleteProduct}/>
                         })}
                            <div className={s.basketFooter}>
                                <div className={s.title}>{t('pay')}: <span>{getSum()}</span>₴</div>
                                {getSum() < 500 ? <div className={[`${s.warning} ${s.warningSumm}`]}>{t('freeDelivery')}</div> : null}
                                    <Button handleClick={openCheckout} title={t('next')}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Basket;