import React from 'react';
import {connect} from 'react-redux';
import {getMenu, updateProduct, updateModifire} from '../../redux/menu';
import { withRouter } from "react-router";
import Products from './products';

import {
    scroller,
    scrollSpy
  } from "react-scroll";
  
class Menu extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeCategory: '287d89b9-97ed-ff01-017b-07017001b6d9'
        };
    }

    componentDidMount(){
        window.scroll(0, 0);
        const id = this.props.match.params.id;

        if (id) {
            this.updateCategory(id);
        } else {
            this.updateCategory(this.props.categories[0].id);
        }
    }

    updateCategory = (id) => {
        this.setState({
            activeCategory: id
        });
        this.props.history.push(`/menu/${id}`);
        this.openLink('menu');
    }

    openLink = (link) => {
        scroller.scrollTo(link, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -50
        });
    }

componentWillUnmount() {
    scrollSpy.unmount();
    scroller.unmount();
  }


    render(){
        return(
                <Products categories={this.props.categories}
                          languageMenu={this.props.languageMenu}
                          products={this.props.products}
                          activeCategory={this.state.activeCategory}
                          updateCategory={this.updateCategory}
                          updateProduct={this.props.updateProduct}
                          updateModifire={this.props.updateModifire}
                          activeCity={this.props.activeCity}
                          cities={this.props.cities}
                          hideProducts={this.props.data ? this.props.data.hiddenProducts ? this.props.data.hiddenProducts : [] : []}
                          />
        )
    }
}


const matStateToProps = (state) => {
    return {
        products: state.menu.products,
        categories: state.menu.categories,
        basket: state.menu.basket,
        languageMenu: state.menu.languageMenu,
        activeCity: state.menu.activeCity,
        cities: state.menu.cities,
        data: state.menu.data,
        activeCategory: state.menu.activeCategory,
    }
}

export default connect(matStateToProps, {getMenu, updateProduct, updateModifire})(withRouter(Menu));
