import React from "react";

let PrivacyPolicyEN = (props) => {
	return (
		<div className={"container"}>
			<div className={"row"}>
				<div>
					<h3>Privacy policy</h3>
					<p>
						These Rules for the processing, storage and protection of customer
						data (hereinafter referred to as the Rules) determine the procedure
						for handling the data of users registered on the{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						website and in the application.
					</p>
					<h4>1. Main provisions.</h4>
					<p>
						By agreeing to these Rules, the User gives permission to collect,
						store, use and disclose his data (including, but not necessarily,
						personal data) in accordance with these Rules, the Law of Ukraine
						"On Consumer Protection", "On the Protection of Personal Data", "On
						the Protection of Information in Information and Telecommunication
						Systems".
					</p>
					<p>
						The Rules are valid from the moment of user registration on{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						and in the application.
					</p>
					<h4>2. Collection and use of personal data about the client.</h4>
					<p>Collection and use of personal data about the client.</p>
					<p>
						The User's data is stored on the server of the{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						website, the card database server and processed using an automated
						system.
					</p>
					<p>
						The purpose of processing the User's data: to ensure the
						implementation of administrative and legal relations, relations in
						the field of tax and accounting, advertising, other relations in
						which user data is used.
					</p>
					<p>
						The following data may be collected, stored and used on the website{" "}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						and in the application:
					</p>
					<ul>
						<li>mobile phone number;</li>
						<li>city;</li>
						<li>
							other data provided by the User through registration on the{" "}
							<a href="https://sunsushi.com.ua/">
								<b>sunsushi.com.ua</b>
							</a>{" "}
							website and in the application.
						</li>
					</ul>
					<p>The User agrees that the data provided by them can be used for:</p>
					<ul>
						<li>
							registration of the user on the website{" "}
							<a href="https://sunsushi.com.ua/">
								<b>sunsushi.com.ua</b>
							</a>{" "}
							and in the application;
						</li>
						<li>
							notifying the Institution where the table is booked about the
							details of the reservation;
						</li>
						<li>
							providing services and customer support at the request of the
							client;
						</li>
						<li>
							improving its own services, website content and advertising;
						</li>
						<li>
							notifying the client about services, news and promotional offers
							via SMS, e-mail, mail, etc.
						</li>
					</ul>
					<h4>3. Disclosure of data provided by the client, user.</h4>
					<p>User data is provided to third parties in the following cases:</p>
					<ul>
						<li>additional consent of the client is obtained;</li>
						<li>
							providing general information (statistics, generalized
							information) that does not contain personal information and does
							not identify the client individually;
						</li>
						<li>
							providing information to law enforcement and other government
							agencies in response to their official request regarding a
							criminal investigation or alleged illegal activity.
						</li>
					</ul>{" "}
					<h4>
						4. Deletion of the provided data (including personal data) and
						unsubscription from the newsletter.
					</h4>
					<p>
						If the User's data specified in clause 2 changes, as well as if the
						User no longer wants to use the services of the
						{""}
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>
						{""} website or application, his data is subject to change or
						deletion.
					</p>
					<p>
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						reserves the right to use the User's data for the purpose for which
						this information was collected within one month after deactivation
						of the information.
					</p>
					<h4>5. Data protection.</h4>
					<p>
						<a href="https://sunsushi.com.ua/">
							<b>sunsushi.com.ua</b>
						</a>{" "}
						undertakes to take appropriate security measures to protect against
						unauthorized interference or unauthorized alteration, disclosure or
						destruction of User data.
					</p>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicyEN;
