import React, { Component } from 'react';
import {connect} from 'react-redux';
import {getMenu, updateProduct, updateActiveCategory} from '../../redux/menu';
import { withRouter } from "react-router";

import Main from './main';

import {
    scroller,
    scrollSpy
  } from "react-scroll";


class MainContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            activeCategory: null
        };
    }



      onRouteChanged() {

    }

  componentDidUpdate() {
    //    var url = null;
    //     if(url !== this.props.match.params.link){
    //         this.openLink(this.props.match.params.link);
    //     }
    //     url = this.props.match.params.link;
    }

    componentDidMount(){
        window.scroll(0, 0);

        // let firstCategory; 

        // this.props.categories.sort((a, b) => a.order-b.order).map((item, index) => {
        //     let search = this.props.products.find(it => it.parentGroup === item.id);

        //     if (search) {
        //         firstCategory = '897afafc-f0d9-49f1-b137-f4d62e1e04ef';
        //     }

        // });

        this.setState({
            activeCategory: this.props.categories.length > 0 ? this.props.categories[0].id: null
        });

    }


    openLink = (link) => {
        scroller.scrollTo(link, {
          duration: 0,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -650
        });
    }

componentWillUnmount() {
    scrollSpy.unmount();
    scroller.unmount();
  }

  updateCategory = (id) => {
    let path = this.props.location.pathname;
    const pushUrl = `menu/${id}`;

    if (path.indexOf('s/delivery')) {
        path = path.replace('s/delivery', '');
    }

    const url = path + pushUrl;

    this.setState({
        activeCategory: id
    });
    
    this.props.updateActiveCategory(id);

    this.props.history.push(url);
    this.openLink('menu');
    }

    addProductBasket = (id) => {
        let index = this.props.products.findIndex(item => item['id'] === id);
        this.props.updateProduct('increment', id);
    }


    render() {
        return(
           <Main categories={this.props.categories}
                 products={this.props.products}
                 basket={this.props.basket}
                 activeCategory={this.state.activeCategory ? this.state.activeCategory : this.props.categories.length > 0 ? this.props.categories[0]['id'] : 0}
                 updateCategory={this.updateCategory}
                 updateProduct={this.props.updateProduct}
                 languageMenu={this.props.languageMenu}
                 cities={this.props.cities}
                 activeCity={this.props.activeCity}
                 slider={this.props.data ? this.props.data.banners : []}
                 hideProducts={this.props.data ? this.props.data.hiddenProducts : []}
                />
        )
    }

}

const matStateToProps = (state) => {
    return {
        products: state.menu.products,
        categories: state.menu.categories,
        basket: state.menu.basket,
        languageMenu: state.menu.languageMenu,
        cities: state.menu.cities,
        activeCity: state.menu.activeCity,
        data: state.menu.data,
    }
}

 export default connect(matStateToProps, {getMenu, updateProduct, updateActiveCategory})(withRouter(MainContainer));