import {actions} from '../API/';
import firebase from '../API/firebase/firebase';
import { getFirebase } from '../redux/firebase';

const UPDATE_MENU = 'UPDATE-MENU';
const UPDATE_ACTIVE_CATEGORY = 'UPDATE-ACTIVE-CATEGORY';
const UPDATE_PRODUCT = 'UPDATE-PRODUCT';
const DELETE_PRODUCT = 'DELETE-PRODUCT';
const UPDATE_LANGUAGE = 'UPDATE-LANGUAGE';
const REMOVE_PRODUCT = 'REMOVE-PRODUCT';
const UPDATE_ACTIVE_CITY = 'UPDATE-ACTIVE-CITY';
const UPDATE_DATA = 'UPDATE-DATA';
const UPDATE_STR = 'UPDATE_STR';
const UPDATE_MODIFIRE = 'UPDATE_MODIFIRE';
const UPDATE_GROUP_MODIFIRE = 'UPDATE-GROUP-MODIFIRE';
const REMOVE_MODIFIRE = 'REMOVE-MODIFIRE';
const REMOVE_GROUP_MODIFIRE = 'REMOVE-GROUP-MODIFIRE';
const REMOVE_MODIFIERS_ON_PRODUCT = 'REMOVE-MODIFIERS-ON-PRODUCT';

let initialState = {
    basket: [],
    basketCount: 0,
    products: [],
    categories: [],
    activeCategory: null,
    languageMenu: 'uk',
    cities: [
        {
            name: 'Олександрія',
            id: '1',
            location: {lat: 50.46632608159737, lng: 30.511793898123823},
            url: '/',
            terminals: [
                {
                    id: '1',
                    name: 'вул. Братська (Калініна), 30',
                    time: '09:00 - 19:00'
                },
            ],
            map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2634.820541945121!2d33.1183793656677!3d48.67067472926958!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40da779252c155a5%3A0xb8fd8b73133ca78!2sSunSushi!5e0!3m2!1sru!2sua!4v1637238671537!5m2!1sru!2sua',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '54e4c0bd-f9ce-4b75-a263-2a9eb755b9d1'
                },
                {
                    name: 'online',
                    id: '64c3b2ba-ff46-447b-b500-4d03c274c24c'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '54e4c0bd-f9ce-4b75-a263-2a9eb755b9d1'
                },
                {
                    name: 'online',
                    id: '64c3b2ba-ff46-447b-b500-4d03c274c24c'
                }
            ],
            liqPay: null
        }
    ],
    activeCity: null,
    data: null,
    streets: []
}

const Menu = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MENU: {
            return {
                ...state,
                products: action.data.products.filter(element => element !== null),
                categories: action.data.categories
            }
        }
        case UPDATE_ACTIVE_CATEGORY: {
            return {
                ...state,
                activeCategory: action.id
            }
        }
        case UPDATE_DATA: {
            return {
                ...state,
                data: action.data
            }
        }
        case UPDATE_LANGUAGE: {
            console.log('--- language redux ----', action);
            return {
                ...state,
                languageMenu: action.language
            }
        }
        case UPDATE_STR: {
            return {
                ...state,
                streets: action.data
            }
        }
        case UPDATE_PRODUCT:
            return {
                ...state,
                products: state.products.map(n => n['id'] === action.id
                ? 
                {
                    ...n,
                    count: Math.max(0, n.count === undefined || n.count === 0 ? 1 : n.count + (({
                    increment:  1,
                    decrement: -1,
                })[action.action]) || 0),
                }
                : n
                ),
                basketCount:  Math.max(0, state.basketCount + (({
                    increment:  1,
                    decrement: -1,
                })[action.action]) || 0),
            }
            case UPDATE_MODIFIRE: {
                return {
                    ...state,
                products: state.products.map(n => n['id'] === action.id
                ? 
                {
                    ...n,
                    modifiers: n.modifiers.map(a => a['modifierId'] === action.modifierId
                    ? 
                    {
                        ...a,
                        amount: Math.max(0, a.amount === undefined || a.amount === 0 ? 1 : a.amount + (({
                        increment:  1,
                        decrement: -1,
                    })[action.action]) || 0),
                    }
                    : a
                    )
                }
                : n
                )
                }
            }
            case UPDATE_GROUP_MODIFIRE: {
                return {
                    ...state,
                products: state.products.map(n => n['id'] === action.id
                ? 
                {
                    ...n,
                    groupModifiers: n.groupModifiers.map(g => g['modifierId'] === action.groupModifiers
                    ? 
                    {
                        ...g,
                        childModifiers: g.childModifiers.map(c => c['modifierId'] === action.childModifiers
                        ? 
                        {
                            ...c,
                            amount: Math.max(0, c.amount === undefined || c.amount === 0 ? 1 : c.amount + (({
                            increment:  1,
                            decrement: -1,
                        })[action.action]) || 0),
                        }
                        : c
                        )
                    } : g)
                }
                : n
                )
                }
            }
            case DELETE_PRODUCT: {
                return {
                    ...state,
                    products: state.products.map(n => n['id'] === action.id ? 
                        {
                            ...n,
                            count: null,
                        }
                            : n),
                            basketCount: state.basketCount - state.products.find(n => n['id'] === action.id).count}
            }
            case REMOVE_PRODUCT: {
                return {
                    ...state,
                    products: state.products.map(n => n['count'] > 0 ? 
                        {
                            ...n,
                            count: n.edit !== false ? null : 1,
                        }
                            : n),
                            basketCount: 0
                    }
            }
            
            case REMOVE_MODIFIRE: {
                return {
                    ...state,
                    products: state.products.map(n => n['id'] === action.id ? 
                        {
                            ...n,
                            modifiers: n.modifiers.map(a => a['modifierId'] === action.modifierId
                            ? 
                            {
                                ...a,
                                amount: 0
                            }
                            : a
                            )
                        }
                            : n),
                    }
            }
            case REMOVE_GROUP_MODIFIRE: {
                return {
                    ...state,
                    products: state.products.map(n => n['id'] === action.id ? 
                        {
                            ...n,
                            groupModifiers: n.groupModifiers.map(a => a['modifierId'] === action.groupModifiers
                            ? 
                            {
                                ...a,
                                childModifiers: a.childModifiers.map(c => c['modifierId'] === action.childModifiers
                                ? 
                                {
                                    ...c,
                                    amount: 0
                                } : c
                                )
                            }
                            : a
                            )
                        }
                            : n),
                    }
            }
            case UPDATE_ACTIVE_CITY: {
                return {
                    ...state,
                    activeCity: action.data
                }
            }
        default:
            return state
    }
}

export const updateMenu = (data) => ({type: UPDATE_MENU, data});
export const updateActiveCategory = (id) => ({type: UPDATE_ACTIVE_CATEGORY, id});
export const updateLanguage = (language) => ({type: UPDATE_LANGUAGE, language});

export const updateProduct = (action, id) => ({type: UPDATE_PRODUCT, action, id});
export const deleteProduct = (id) => ({type: DELETE_PRODUCT, id});
export const removeProduct = () => ({type: REMOVE_PRODUCT});
export const updateActiveCity = (data) => ({type: UPDATE_ACTIVE_CITY, data});
export const updateData = (data) => ({type: UPDATE_DATA, data});
export const updateStreets = (data) => ({type: UPDATE_STR, data});

export const updateModifire = (action, id, modifierId) => ({type: UPDATE_MODIFIRE, action, id, modifierId});
export const updateGroupModifire = (action, id, groupModifiers, childModifiers) => ({type: UPDATE_GROUP_MODIFIRE, action, id, groupModifiers, childModifiers});
export const removeModifire = (id, modifierId) => ({type: REMOVE_MODIFIRE, id, modifierId});
export const removeGroupModifire = (id, groupModifiers, childModifiers) => ({type: REMOVE_GROUP_MODIFIRE, id, groupModifiers, childModifiers});
export const removeModifiresOnProduct = (id) => ({type: REMOVE_MODIFIERS_ON_PRODUCT, id});

export const getMenu = (items) => {
    return async (dispatch) => {
        let defaultHiddenCategories = [];
        let defaultHiddenProducts = [];

        const getHiddenCategories = (categories, products) => {
            const hiddenCategories = [];
        
            for (let idCategory in categories) {
                const category = categories[idCategory];
                const categoryProducts = [];
        
                for (let key in products) {
                    const product = products[key];

                    if (category.id === product.menuCategoryId) {
                        categoryProducts.push(product);
                    }
                }

                if (categoryProducts.length === 0) {
                    hiddenCategories.push(category);
                }
        
                categoryProducts.length = 0;
            }

            return hiddenCategories;
        }

        const sortCategory = (categoryId, items) => {
            const index = items.settings.Category.categoriesOrder.findIndex(t => t === categoryId);
            return index;
        }

        if (items) {
            console.log('items firebase', items);
            if (items.settings.Category.hiddenCategories) {
                items.settings.Category.hiddenCategories.map(item => defaultHiddenCategories.push(item));
                dispatch(updateData(items));
                dispatch(getFirebase(items));
            }

            if (items.settings.Category.hiddenProducts) {
                items.settings.Category.hiddenProducts.map(item => defaultHiddenProducts.push(item));
                dispatch(updateData(items));
                dispatch(getFirebase(items));
            }
            
            const categories = [];
            const products = [];

            for (let key in items.categories) {
                categories.push({ ...items.categories[key], id: key });
            }

            for (let key in items.products) {
                products.push({ ...items.products[key], id: key });
            }

            let hiddenCategories = getHiddenCategories(categories, products);
                hiddenCategories = hiddenCategories ? hiddenCategories : [];
                defaultHiddenCategories = defaultHiddenCategories ? defaultHiddenCategories : [];
                hiddenCategories = [...hiddenCategories, ...defaultHiddenCategories];

            let filtredCategories = categories.filter(category => {
                return !hiddenCategories.find(categoryId => {
                    return categoryId.id === category.id
                });
            });

            // console.log('22', categories.filter(category => {
            //     return hiddenCategories.find(categoryId => categoryId !== category.id);
            // }))

            filtredCategories.forEach((category, index) => {
                const newCategory = { ...category, order: sortCategory(category.id, items) };
                filtredCategories[index] = newCategory;
            });

            filtredCategories.sort((first, second) => first.order - second.order);

            let hiddenProducts = products.filter(product => !product.isIncludedInMenu);
                hiddenProducts = hiddenProducts ? hiddenProducts : [];
                defaultHiddenProducts = defaultHiddenProducts ? defaultHiddenProducts : [];
                hiddenProducts = [...hiddenProducts, ...defaultHiddenProducts]

            const filtredProducts = products.filter(product => 
                !hiddenProducts.find(productId => productId === product.id));


            console.log('filtredCategories -->>> ***', filtredCategories);
            console.log('filtredProducts -->>>', filtredProducts);
            dispatch(updateActiveCategory(filtredCategories[0].id));
            dispatch(updateMenu({categories: filtredCategories, products: filtredProducts}));

            return filtredCategories.length > 0 && filtredProducts.length > 0;
        }
    }
}

export default Menu;