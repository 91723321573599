import React from 'react';

import PreloaderFull from './preloaderFull';
import PreloaderSmall from './preloaderSmall';

const Preloader = (props) => {
    return(
        <>
            {props.full ? <PreloaderFull/> :  <PreloaderSmall/>}
        </>
    )
}

export default Preloader;