import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveCategory } from '../../../../redux/menu';

import s from './categories.module.css';

const Categories = (props) => {
    return(
        <div className={s.categories_container}>
            <div className={s.menu_categories}>
                {props.categories
                    .sort((a, b) => a.order-b.order)
                    .map((item, index) => {
                        let search = props.products.find(it => it.menuCategoryId === item.id);
                        if (search) {
                            return (
                                <span key={index} onClick={() => props.updateCategory(item.id)} className={props.activeCategory === item.id ? s.active : ''}>
                                    {item.name[props.languageMenu]}
                                </span>
                            )
                        }
                })}
            </div>
        </div>
    )
}

export default Categories;