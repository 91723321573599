import React from 'react';
import { useSelector } from 'react-redux';

import s from './product.module.css';
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import logo from '../../../assets/logo.jpg';

const ProductCard = (props) => {
    const { t, i18n } = useTranslation();
    const cities = useSelector(state => state.menu.cities);
    const activeCity = useSelector(state => state.menu.activeCity);
    const city = cities.find(t => t.id === activeCity.id);

    const getSum = () => {
        let sum = 0;
        let price = props.item.spots[0].price;
        sum += +price / 100;             
        return sum;
    }

    // console.log('getSum -->>>', getSum());`

    return(
        <div className={s.product}>
            <div className={s.product_row}>
                <div onClick={() => props.history.replace(`/menu/product/${props.item['id']}`)} className={s.product_img} 
                 style={{backgroundImage: `url(${ props.item.photo.replace(' ', '%20')})`
                }}
                />
                    <div onClick={() => props.history.replace(`/menu/product/${props.item['id']}`)}  className={s.product_title}>
                        <span>{props.item['name'] ? props.item['name'][props.languageMenu] : ''}</span>
                    </div>
                        <div onClick={() => props.history.replace(`/menu/product/${props.item['id']}`)} className={s.product_description}>
                        <span>{props.item['description'] ? props.item['description'][props.languageMenu] : ''}</span>
                        </div>
                        <div className={s.product_footer}>
                            <div className={s.price}>
                                <span>{props.item.count && props.item.count > 1 ? getSum()*props.item.count : getSum()}</span> ₴
                            </div>
                            <div className={s.add_container}>
                                {props.item.count > 0 ? <div className={[`${s.count_product} ${s.count}`]}>
                                    <div className={s.minus} onClick={() => props.updateProduct('decrement', props.item['id'])}></div>
                                        <div className={s.result}>{props.item.count}</div>
                                    <div className={s.plus} onClick={() => props.updateProduct('increment', props.item['id'])}></div>
                                </div> :  <div onClick={() => {
                                                props.updateProduct('increment', props.item['id']);;
                                            }
                                       } className={s.add_product}>
                                    <span>{t('add')}</span>
                                 </div>}
                
                            </div>
                        </div>
                    </div>
                </div>
            )
}

export default withRouter(ProductCard);