import React from 'react';

import s from './product.module.css';
import Categories from './categories/categories';
import Product from './productCard';
import { useTranslation } from "react-i18next";
import Preloader from '../../../components/preloader';

const Products = (props) => {
    let products = props.products;
    let preloader = false;
    let city = props.cities.find(it => it.id === props.activeCity.id);
    let cityProducts = [];
    
    const { t, i18n } = useTranslation();
    console.log('props, props', props);
    return(
        <div className='container'>
            <div className='row' style={{position: 'relative'}}>
                <h3>{t('menu')}</h3>
                {preloader && <Preloader/>}
                <Categories 
                    languageMenu={props.languageMenu} 
                    products={props.products}  
                    categories={props.categories} 
                    activeCategory={props.activeCategory} 
                    updateCategory={props.updateCategory}
                />
                <div className={s.products_container}>
                    {products
                        .filter(t => t.menuCategoryId === props.activeCategory)
                        .sort((a, b) => a.order-b.order)
                        .map((item, index) => {
                            return(
                                    <Product 
                                        languageMenu={props.languageMenu} 
                                        item={item} 
                                        updateProduct={props.updateProduct} 
                                        key={index}
                                    />
                            )  
                    })}
                </div>
            </div>
        </div>
    )
}

export default Products;