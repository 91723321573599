import React from 'react';
import s from './modal.module.css';
import { useTranslation } from "react-i18next";

import tick from '../../assets/tick.svg';
import error from '../../assets/error.svg';

import Button from '../../components/button';
import { withRouter } from "react-router";

const ModalResult = (props) => {
    const { t, i18n } = useTranslation();
    console.log('props ModalResult', props);
    return(
        <div className={s.overlay}>
            <div className={s.modal}>
                <div className={s.modal_container}>
                    <h3>{t('city')}</h3>
                    <div className={s.languages}>
                        {props.cities.map((it, index) => {
                            return(
                                <a key={index} href={it.url}  className={[props.activeCity.id == it.id && s.active]}>{it.name}</a>
                                )
                           /* return(
                            <span key={index} onClick={() => props.selectLanguage(it)} className={[props.activeCity.id == it.id && s.active]}>{it.name}</span>
                            )*/
                        })}
                    </div>
                    <Button title={t('close')} 
                        handleClick={props.handleClick}/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ModalResult);