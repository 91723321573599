import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	Field,
	reduxForm,
	formValueSelector,
	reset,
	arrayPush,
} from "redux-form";
import { required, date, validateForm } from "redux-form-validators";
import {
	Input,
	File,
	Textarea,
	Select,
	Time,
	Radio,
} from "../../components/ui/Input";
import s from "./checkout.module.css";
import Button from "../../components/button/";
import { createNumberMask, createTextMask } from "redux-form-input-masks";
import Autocomplete from "../../components/ui/Input/Autocomplete";
import AutocompleteCustom from "../../components/ui/Input/AutocompleteCustom";
import { useTranslation, withTranslation } from "react-i18next";
import moment from "moment";

const phoneMask = createTextMask({
	pattern: "+38(999) 999-9999",
});

const timeMask = createTextMask({
	pattern: "99:99",
	stripMask: false,
});

const requiredField = (value) => (value ? undefined : "Обов'язкове поле");

const validPhone = (value) => {
	if (value.length < 10) {
		return "Введіть правильний номер телефону";
	}
};

const sumOrder = (value, sum, pushType) => {
	if (value === "1" && sum < 0) {
		return "Мінімальна сума замовлення - 150₴";
	}
};

const getTimeFromMins = (mins) => {
	var h = (mins / 60) | 0,
		m = mins % 60 | 0;
	return moment.utc().hours(h).minutes(m).format("HH:mm");
};

const validSelectTime = (value, _, props) => {
	// console.log('validSelectTime');

	if (!value || value == 1) {
		const city = props.cities.find((t) => t.spot_id === props.city);

		if (city) {
			const { begin, end, orderTimeIntervalDelivery, orderTimeIntervalPickup } =
				city.workingHours[0];

			let time = moment(new Date());
			const interval =
				props.delivery == 2
					? orderTimeIntervalPickup
					: orderTimeIntervalDelivery;
			let minTime = moment(
				new Date(moment().format("YYYY/MM/DD") + ` ${begin}:00`)
			);
			let maxTime = moment(
				new Date(moment().format("YYYY/MM/DD") + ` ${end}:00`)
			);
			let minTimeOrder = moment(
				new Date(moment().format("YYYY/MM/DD") + ` ${begin}:00`)
			);
			minTimeOrder = minTimeOrder.add(interval, "minutes");
			let timeInterval = moment().add(interval, "minutes");

			// console.log('time', time);
			// console.log('minTimeOrder', minTimeOrder);
			// console.log('minTime', minTime);
			// console.log('***', time < minTimeOrder && time < minTime);

			if (time < minTime || timeInterval < minTime) {
				return `Замовлення приймаємо з ${moment(minTimeOrder).format("HH:mm")}`;
			} else if (time > maxTime || timeInterval > maxTime) {
				return `Замовлення приймаємо до ${moment(maxTime).format("HH:mm")}`;
			} else if (time < minTimeOrder && time < minTime) {
				return `Мінімальний час доставки замовлення ${moment(
					minTimeOrder
				).format("HH:mm")}`;
			}
		}
	}

	return null;
};

const validTime = (value, _, props) => {
	console.log("--- validTime доставка *** ---", value, props);

	const city = props.cities.find((t) => t.spot_id === props.city);

	if (city) {
		const { begin, end, orderTimeIntervalDelivery } = city.workingHours[0];
		let actualTime = moment(new Date());
		let time = moment(
			new Date(moment().format("YYYY/MM/DD") + " " + value + ":00")
		);
		let minTime = moment(
			new Date(moment().format("YYYY/MM/DD") + ` ${begin}:00`)
		);
		let maxTime = moment(
			new Date(moment().format("YYYY/MM/DD") + ` ${end}:00`)
		);
		let minTimeOrder = moment(
			new Date(moment().format("YYYY/MM/DD") + ` ${begin}:00`)
		);
		minTimeOrder = minTimeOrder.add(orderTimeIntervalDelivery, "minutes");
		let timeInterval = moment().add(orderTimeIntervalDelivery, "minutes");
		actualTime = actualTime.add(orderTimeIntervalDelivery, "minutes");

		// console.log('Actual time -->>>', actualTime);
		// console.log('time ** __>>', time);
		console.log("time", time);
		console.log("timeInterval", timeInterval);
		console.log("minTime", minTime);
		console.log("maxTime", maxTime);
		console.log("minTimeOrder", minTimeOrder);

		console.log("orderTimeIntervalDelivery", orderTimeIntervalDelivery);
		console.log("timeInterval vs maxTime", timeInterval > maxTime);

		// if (time < minTime || timeInterval < minTime) {
		// 	return `Замовлення приймаємо з ${moment(minTime).format("HH:mm")}`;
		// } else
		if (time > maxTime || timeInterval > maxTime) {
			return `Замовлення приймаємо до ${moment(maxTime).format("HH:mm")}`;
		} else if (time < minTimeOrder) {
			return `Мінімальний час доставки замовлення ${moment(minTimeOrder).format(
				"HH:mm"
			)}`;
		} else if (timeInterval < maxTime && time < timeInterval) {
			return `Замовлення приймаємо з ${moment(timeInterval).format("HH:mm")}`;
		}
	}
};

const validTimePi = (value, _, props) => {
	console.log("--- validTimePi самовывоз ---");

	const city = props.cities.find((t) => t.spot_id === props.city);

	if (city) {
		const { begin, end, orderTimeIntervalPickup } = city.workingHours[0];

		// let actualTime = moment(props.actualTime);
		let time = moment(
			new Date(moment().format("YYYY/MM/DD") + " " + value + ":00")
		);
		let minTime = moment(
			new Date(moment().format("YYYY/MM/DD") + " " + begin + ":00")
		);
		let maxTime = moment(
			new Date(moment().format("YYYY/MM/DD") + " " + end + ":00")
		);
		let minTimeOrder = moment(
			new Date(moment().format("YYYY/MM/DD") + " " + begin + ":00")
		);
		minTimeOrder = minTimeOrder.add(orderTimeIntervalPickup, "minutes");
		let timeInterval = moment().add(orderTimeIntervalPickup, "minutes");

		console.log("time *** >>>", time);
		console.log("timeInterval *** >>>", timeInterval);

		// if (time < minTime || timeInterval < minTime) {
		//    return `Замовлення приймаємо з ${moment(minTimeOrder).format('HH:mm')}`;
		// } else
		if (time > maxTime || timeInterval > maxTime) {
			return `Замовлення приймаємо до ${moment(maxTime).format("HH:mm")}`;
		} else if (time < minTimeOrder) {
			return `Мінімальний час доставки замовлення ${moment(minTimeOrder).format(
				"HH:mm"
			)}`;
		} else if (timeInterval < maxTime && time < timeInterval) {
			return `Замовлення приймаємо з ${moment(timeInterval).format("HH:mm")}`;
		}
	}
};

let CheckoutForm = (props) => {
	const { handleSubmit, delivery, typePay } = props;
	const { t, i18n } = useTranslation();
	const [isDeliveryField, setIsDeliveryField] = useState(false);
	const [isValidDeliveryField, setIsValidDeliveryField] = useState(true);
	const [timeId, setTimeId] = useState(1);

	useEffect(() => {
		if (props.amount < 500 && props.delivery == 1) {
			setIsDeliveryField(true);
			setIsValidDeliveryField(false);
		} else {
			setIsValidDeliveryField(true);
		}
	}, [props.amount, props.delivery]);

	const selectDeliveryChange = (event) => {
		const value = event.target.value;
		const validSelector = value == 1 && props.amount < 500;

		if (value == 1) setIsDeliveryField(true);

		setIsValidDeliveryField(!validSelector);
	};

	const handleChange = (e) => {
		setTimeId(e.target.value);
	};

	// const handleValidate = isDeliveryField ? isValidDeliveryField && handleSubmit : handleSubmit;
	const handleValidate = isDeliveryField ? handleSubmit : handleSubmit;
	const selectTime = [
		{ id: 1, name: "Щонайшвидше" },
		{ id: 2, name: "На певний час" },
	];

	let options = props.getOption();

	console.log("options", options);

	// let options = delivery == 2 ? props.typesPay : props.typePayDelivery;
	// let options = delivery == 2 ? props.typesPay.filter(t => t.name === 'online'): props.typesPay.filter(t => t.name === 'online');
	//  options = props.productsArray.find(t => t.ParentCode === '100000112') ? options.filter(t => t.name !== 'online') : options;

	return (
		<div className={"container"}>
			<div className={"row"}>
				<div className={s.formContainer}>
					<h3>{t("toOrder")}</h3>
					<form
						onSubmit={handleSubmit}
						action="https://secure.wayforpay.com/pay"
						autoComplete="off"
					>
						<Field
							name={`name`}
							component={Input}
							label={t("name")}
							validate={requiredField}
						/>
						<Field
							name={`phone`}
							component={Input}
							label={t("phone")}
							validate={[requiredField, validPhone]}
							{...phoneMask}
						/>

						{/* <Field name={`delivery`} option={props.typeDelivery}
                            component={Select} label={t('typeDelivery')} 
                           //  validate={(val) => sumOrder(val, props.amount, props.dispatch)}
                            validate={(val) => checkOrder(val, props.amount, 'delivery')}
                       /> */}

						<div className={s.deliveryFieldWrapper}>
							<Field
								name={`delivery`}
								className={s.deliveryField}
								component="select"
								onChange={selectDeliveryChange}
								label={t("typeDelivery")}
							>
								{props.typeDelivery.map((item, index) => (
									<option key={index} value={item.id}>
										{t(item.name)}
									</option>
								))}
							</Field>
							{isDeliveryField && (
								<span className={s.messageError}>
									Мінімальна сума для замовлення Безкоштовної доставки - 500
									грн.
								</span>
							)}
						</div>

						{delivery == 1 ? (
							<div>
								{/* <Field name='streetname'
                                  component={AutocompleteCustom} dispatch={props.dispatch} label={t('street')} 
                                  validate={requiredField}
                                  city={props.city}
                                  streets={props.streets}
                           /> */}
								<div>
									<Field
										name="streetname"
										component={Input}
										label={t("street")}
										validate={requiredField}
									/>
								</div>
								<div className={s.deliveryContainer}>
									<div className={s.item}>
										<Field
											name="house"
											component={Input}
											label={t("building")}
											validate={requiredField}
										/>
									</div>
									<div className={s.item}>
										<Field name="porch" component={Input} label={t("porch")} />
									</div>
									<div className={s.item}>
										<Field
											name="floor"
											component={Input}
											label={`${t("floor")}`}
										/>
									</div>
									<div className={s.item}>
										<Field
											name="housing"
											component={Input}
											label={`${t("housing")}`}
										/>
									</div>
									<div className={s.item}>
										<Field
											name="appartment"
											component={Input}
											label={`${t("appartment")}/${t("office")}`}
										/>
									</div>
								</div>
							</div>
						) : (
							<Field
								name={`terminals`}
								option={props.terminals}
								component={Select}
								label={t("terminals")}
							/>
						)}
						<Field
							name={`selectTime`}
							option={selectTime}
							value={timeId}
							onChange={handleChange}
							component={Select}
							label={t("selectTime")}
							validate={[validSelectTime]}
						/>

						{timeId == 2 && (
							<Field
								name={`time`}
								component={Time}
								label={t("time")}
								validate={[
									requiredField,
									delivery == 1 ? validTime : validTimePi,
								]}
								type={"time"}
							/>
						)}

						<Field
							name={`typePay`}
							option={options}
							component={Select}
							label={t("typePay")}
						/>
						<Field name={`comment`} component={Textarea} label={t("comment")} />

						<div className={s.buttonContainer}>
							<Button title={t("confirm")} handleClick={handleValidate} />
						</div>
					</form>
				</div>

				<form
					className={s.payForm}
					ref={props.formRef}
					method="post"
					action="https://secure.wayforpay.com/pay"
				>
					<p>merchantAccount</p>
					<input name="merchantAccount" value="mirafoodsmarket_com" />
					<p>merchantAuthType</p>
					<input name="merchantAuthType" value="SimpleSignature" />
					<p>merchantDomainName</p>
					<input name="merchantDomainName" value="mirafoodmarket" />
					<p>clientFirstName</p>
					<input name="clientFirstName" value="Test" />
					<p>serviceUrl</p>
					<input name="serviceUrl" value="" />
					<p>merchantSignature</p>
					<input name="merchantSignature" value="" />
					<p>orderReference</p>
					<input name="orderReference" value="" />
					<p>orderDate</p>
					<input name="orderDate" value="" />
					<p>amount</p>
					{/* <input name="amount" value={1}/> */}
					<input name="amount" value={props.amount} />
					<p>currency</p>
					<input name="currency" value="UAH" />
					<p>orderTimeout</p>
					<input name="orderTimeout" value="49000" />

					{props.productsArray.map((item, index) => {
						return (
							<div key={index}>
								<input name="productName[]" value={item.name} />
								<input name="productPrice[]" value={item.price} />
								<input name="productCount[]" value={item.count} />
							</div>
						);
					})}
					<p>defaultPaymentSystem</p>
					<input name="defaultPaymentSystem" value="card" />

					<button ref={props.btRef} type="submit">
						Validate
					</button>
				</form>
			</div>
		</div>
	);
};

const afterSubmit = (_, dispatch) => dispatch(reset("CheckoutForm"));

CheckoutForm = reduxForm({
	form: "CheckoutForm",
	onSubmitSuccess: afterSubmit,
})(CheckoutForm);

const selector = formValueSelector("CheckoutForm");

CheckoutForm = connect((state, props) => {
	const { delivery, typePay } = selector(state, "delivery", "typePay");
	return {
		delivery,
		typePay,
		cities:
			state.menu.data.settings && state.menu.data.settings.City
				? state.menu.data.settings.City
				: [],
	};
})(CheckoutForm);

export default CheckoutForm;
