import React from 'react';

import s from './product.module.css';
import Categories from './categories/categories';
import Product from './productCard';
import { useTranslation } from "react-i18next";
import Preloader from '../../components/preloader';
import Scroll from 'react-scroll';

var Element = Scroll.Element;

const Products = (props) => {
    let products = props.products;
    let preloader = false;
    let city = props.cities.find(it => it.id === props.activeCity.id);

    const { t, i18n } = useTranslation();
    return(
        <Element name='menuMain'>
        <div className='container'>
            <div className='row' style={{position: 'relative'}}>
                <h3>{t('menu')}</h3>
                {preloader && <Preloader/>}
                <Categories products={props.products} languageMenu={props.languageMenu} categories={props.categories} activeCategory={props.activeCategory} updateCategory={props.updateCategory}/>
                <div className={s.products_container}>
                    {products
                    .filter(t => t.menuCategoryId === props.activeCategory)
                    .sort((a, b) => a.order-b.order)
                    .map((item, index) => {
                        return(
                                <Product languageMenu={props.languageMenu} item={item} updateProduct={props.updateProduct} updateModifire={props.updateModifire} key={index}/>
                        )  
                    })}
                </div>
            </div>
        </div>
        </Element>
    )
}

export default Products;