import React, { useState, useEffect } from 'react';
import firebase from './API/firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import {getMenu, updateActiveCity, updateData, updateLanguage} from './redux/menu';
import Preload from './components/preloader/preloaderFull';

import Router from './scenes/Router/routerContainer';

const App = () => {
  const dispatch = useDispatch();
  const [preload, setPreload] = useState(false);
  const [loadFb, setLoadFb] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    initCity();
    initFirebase();
    // if (loadFb) initMenu();
  }, []);

  useEffect(() => {
    if (loadFb) {
      initMenu();
    }
  }, [loadFb]);

  const initFirebase = () => {
    setLoadFb(false);
    const database = firebase.database().ref('/');
      database.on('value', (snapshot) => {
        let items = snapshot.val();
        const defaultLanguage = items.adminPermission.defaultLanguage;

        // console.log('items', items);

        if (items) {
          dispatch(updateLanguage(defaultLanguage));
          dispatch(updateData(items));
          setData(items);
          setLoadFb(true);
        }
      });
  }

  const initMenu = () => {
    const status = dispatch(getMenu(data))
      .then(res => {
        if (!res) throw new Error('Error not Firebase data');

        if (res) {
          console.log('res', res);
          setPreload(true);
        }
      })
      .catch(err => {
         setPreload(false);
      });
  }

  const initCity = () => {
    let city = {
      name: 'Олександрія',
      id: '1',
    };

    dispatch(updateActiveCity(city));
  }

  return !preload 
    ? <Preload /> 
    : <Router />;
}

export default App;