import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase';

import Basket from './basket';
import {connect} from 'react-redux';
import {updateProduct, deleteProduct} from '../../redux/menu';


class BasketContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyShop: null,
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        this.getKeyShop();
    }

    getKeyShop = () => {
        const {activeCity, cities} = this.props;
        console.log('cities -->>>s', cities);

        if (activeCity && cities) {
            const cityIndex = cities.findIndex(t => t.spot_id === activeCity.id);
            this.setState({ keyShop: cityIndex });
        }
    }

    getFieldOrderAvailable = () => {
        return firebase.database().ref(`/settings/City/${this.state.keyShop}/orderAvailable`).once('value')
    }

    getWorkingHours = () => {
        return firebase.database().ref(`/settings/City/${this.state.keyShop}/workingHours`).once('value')
    }

    render(){
        return(
            <Basket products={this.props.products} 
                updateProduct={this.props.updateProduct} 
                getFieldOrderAvailable={this.getFieldOrderAvailable} 
                getWorkingHours={this.getWorkingHours} 
                deleteProduct={this.props.deleteProduct}
                languageMenu={this.props.languageMenu}/>
        )
    }
}

const mapStateToProps = (state) => {
    console.log('state', state.menu.data.settings)
    return{
        products: state.menu.products,
        languageMenu: state.menu.languageMenu,
        activeCity: state.menu.activeCity,
        cities: state.menu.data.settings && state.menu.data.settings.City ? state.menu.data.settings.City : [],
    }
}

export default connect(mapStateToProps, {updateProduct, deleteProduct})(withRouter(BasketContainer));