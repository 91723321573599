import React, {Component} from 'react';
import s from './slider.module.css';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./slider.module.css"; 
import mobileVideo from "../../assets/Videos/mobile.mp4";
import desctopVideo from "../../assets/Videos/desctop.mp4";

var EmbedVideo = function({
  video
}) {
   return (
       <div dangerouslySetInnerHTML={{ __html: `
        <video
          width="100%" 
          height="auto"
          muted
          autoplay
          loop 
          playsinline
          src="${video}"
        />,
      ` }}></div>
   )
}

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={s.arrow}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

class SliderContainer extends Component{

  constructor(){
    super();

    this.state = {
      width: null,
      currentSlide: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentDidMount(){
    //this.updateWindowDimensions();
   // window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth});
  }

  render(){
    let settings = {
      dots: true,
      dotsClass: s.button__bar,
      infinite: true,
      lazyLoad: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <Arrow/>,
      prevArrow: <Arrow/>,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            arrows: false
          }
        }
      ],
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next });
      },
      appendDots: dots => {
        return (
            <ul>
              {dots.map((item, index) => {
                return (
                  <li key={index}>{item.props.children}</li>
                );
              })}
            </ul>
        )
      },
      customPaging: index => {
        return (
          <button className={index === this.state.currentSlide ? s.slick_active : null}>
            {index + 1}
          </button>
        )
      }
    };

  const WrapSlider = ({ productId, children, key }) => {
    if (productId) {
      return <Link key={key} to={`/menu/product/${productId}`}>{children}</Link>
    } else {
      return <div key={key}>{children}</div>
    }
  }

  // console.log('this window', window.innerWidth);

  return(
      <div className={s.sliderContainer}>
         {this.props.slider.length === 1 && (
            <div className={s.slideContainer}>
              <div className={s.slide} id="one-video">
                {/* <video width="100%" height="auto" autoPlay loop muted playsInline>
                      <source src={myVideo} type="video/mp4"/>
                      Your browser does not support the video tag.
                </video> */}              
                {window.innerWidth > 1024 && <EmbedVideo video={desctopVideo} />}
                {window.innerWidth < 1024 && <EmbedVideo video={mobileVideo} />}
              </div>
            </div>
          )}
         {/* {this.props.slider.length === 1 && (
            <div className={s.slideContainer}>
              <div className={s.slide}>
                <img src={this.props.slider[0].photo} alt="slide" /> 
              </div>
            </div>
          )} */}
        <Slider {...settings} className={s.slider}>
          {this.props.slider.length > 1 && (
            this.props.slider
              .filter(it => this.props.activeCity.id === it.spotId)
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((item, index) => {
                  return(
                    <WrapSlider productId={item.productId} key={index}>
                      <div className={s.slideContainer}>
                        <div className={s.slide}>
                          <img src={item.photo} alt="banner" /> 
                        </div>
                      </div>
                    </WrapSlider>
                  )
              })
          )}
        </Slider>
      </div>
  )
  }
}

export default SliderContainer;