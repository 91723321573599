const UPDATE_MODIFIERS_ALL = 'UPDATE-MODIFIERS-ALL';
const UPDATE_MODIFIERS = 'UPDATE-MODIFIERS';
const UPDATE_GROUP_MODIFIERS = 'UPDATE-GROUP-MODIFIERS';

const initialValues = {
    productId: "",
    modifiers: [],
    groupModifiers: [],
}

const Modifiers = (state = initialValues, action) => {
    switch(action.type) {
        case UPDATE_MODIFIERS_ALL: {
            return {
                ...action.data
            }
        }
        case UPDATE_MODIFIERS: {
            return {
                ...state,
                modifiers: action.modifiers
            }
        }
        case UPDATE_GROUP_MODIFIERS: {
            return {
                ...state,
                groupModifiers: action.groupModifiers
            }
        }
        default:
            return state
    }
}

export const updateModifiersAll = (data) => ({type: UPDATE_MODIFIERS_ALL, data});
export const updateModifiers = (modifiers) => ({type: UPDATE_MODIFIERS, modifiers});
export const updateGroupModifiers = (groupModifiers) => ({type: UPDATE_GROUP_MODIFIERS, groupModifiers});

export default Modifiers;