import React, { Component } from "react";

import { connect } from "react-redux";
import { actions } from "../../API/";
import Header from "./header/header";
import Footer from "./footer/footer";

import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import MainPage from "../Main/mainContainer";
import Basket from "../Basket/basketContainer";
import Product from "../Product/productContainer";
import Checkout from "../Checkout/checkoutContainer";
import Delivery from "../Main/delivery/delivery";
import AboutUs from "../Main/aboutUs/aboutUs";
import Menu from "../Menu/Menu";
import Success from "../Success/Success";
import PrivacyPolicy from "../Documents/PrivacyPolicy";
import PrivacyPolicyEN from "../Documents/PrivacyPolicyEN";
import Offer from "../Documents/Offer";
import { updateLanguage, updateActiveCity, updateMenu } from "../../redux/menu";

import Language from "../../components/modalLanguage";
import i18n from "../../i18n";
import BreadCrums from "../BreadCrumbs";
import MobileMenu from "./header/mobileMenu";
import MobileNumbers from "./header/mobileNumbers";

class RouterContainer extends Component {
	constructor() {
		super();
		this.state = {
			loadUrl: false,
			languageModal: false,
			language: "uk",
			mobileMenu: false,
			mobileNumbers: false,
		};
	}

	componentDidMount() {
		window.scroll(0, 0);

		let language = localStorage.getItem("language");

		if (language) {
			//i18n.changeLanguage(language);
			this.props.updateLanguage(language);
			this.setState({ language });
		}
	}

	onMobileMenu = () => {
		this.setState({ mobileMenu: true });
	};

	closeMobileMenu = () => {
		this.setState({ mobileMenu: false });
	};

	onMobileNumbers = () => {
		this.setState({ mobileNumbers: true });
	};

	closeMobileNumbers = () => {
		this.setState({ mobileNumbers: false });
	};

	statusLanguage = () => {
		this.setState({
			languageModal: this.state.languageModal ? false : true,
		});
	};

	selectLanguage = (data) => {
		/* localStorage.setItem('language', lng);
        this.props.updateLanguage(lng);
        i18n.changeLanguage(lng);
        this.setState({language: lng})*/
		this.props.updateActiveCity(data);
		localStorage.setItem("city", JSON.stringify(data));
	};
	render() {
		return (
			<>
				{this.state.mobileMenu && (
					<MobileMenu closeMobileMenu={this.closeMobileMenu} />
				)}
				{this.state.mobileNumbers && (
					<MobileNumbers closeMobileNumbers={this.closeMobileNumbers} />
				)}
				<Header
					mobileMenu={this.state.mobileMenu}
					onMobileMenu={this.onMobileMenu}
					closeMobileMenu={this.closeMobileMenu}
					onMobileNumbers={this.onMobileNumbers}
					closeMobileNumbers={this.closeMobileNumbers}
					activeCity={this.props.activeCity}
					basketCount={this.props.basketCount}
					openLink={this.openLink}
					openLanguage={this.statusLanguage}
					language={this.state.language}
				/>
				{this.state.languageModal && (
					<Language
						handleClick={this.statusLanguage}
						selectLanguage={this.selectLanguage}
						language={this.state.language}
						cities={this.props.cities}
						activeCity={this.props.activeCity}
					/>
				)}
				<BreadCrums language={this.state.language} />
				{/* {this.state.loadUrl && ( */}
				<Switch>
					{/* <Redirect from="/:url*(/+)" to={this.props.location.pathname.slice(0, -1)} /> */}
					<Route exact path={"/s/:link"} render={() => <MainPage />} />
					<Route exact path={"/"} render={() => <MainPage />} />
					<Route exact path={"/delivery"} render={() => <Delivery />} />
					<Route exact path={"/about-us"} render={() => <AboutUs />} />
					<Route path={"/checkout"} render={() => <Checkout />} />
					<Route path={"/basket"} render={() => <Basket />} />
					<Route path={"/menu/product/:id"} render={() => <Product />} />
					<Route path={"/menu/:id"} render={() => <Menu />} />
					<Route path={"/menu"} render={() => <Menu />} />
					<Route path={"/success/:phone/:order"} render={() => <Success />} />
					<Route path={"/offer"} render={() => <Offer />} />
					<Route path={"/privacy-policy"} render={() => <PrivacyPolicy />} />
					<Route
						path={"/privacy-policy-en/"}
						render={() => <PrivacyPolicyEN />}
					/>
				</Switch>
				{/* )} */}
				<Footer />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		basketCount: state.menu.basketCount,
		cities: state.menu.cities,
		activeCity: state.menu.activeCity,
		products: state.menu.products,
		categories: state.menu.categories,
	};
};

export default connect(mapStateToProps, {
	updateLanguage,
	updateActiveCity,
	updateMenu,
})(withRouter(RouterContainer));
