import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import s from './categories.module.css';

const Categories = (props) => {
    const url = props.location.pathname;
    return(
        <div className={s.categoriesContainer}>
            <Helmet>
                <meta charSet="utf-8" />
                {/* <title>Title Category</title> */}
                <link rel="canonical" href={url} />
            </Helmet>
            <div className={s.categories_container}>
                <div className={s.menu_categories}>
                    {props.categories
                        .sort((a, b) => a.order-b.order)
                        .map((item, index) => {
                            let search = props.products.find(it => it.menuCategoryId === item.id);
                            if (search) {
                                return(
                                        <span key={index} onClick={() => props.updateCategory(item.id)} className={props.activeCategory === item.id ? s.active : ''}>
                                            {item.name[props.languageMenu]}
                                        </span>
                                    )
                                }
                        })}
                </div>
            </div>
        </div>
    )
}

export default withRouter(Categories);