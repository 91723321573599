import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import s from './menu.module.css'

const MobileMenu = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={s.menuWrapper}>
            <div className={s.navMenu}>
                <NavLink onClick={props.closeMobileMenu} to="/menu/">{t('menu')}</NavLink>
                <NavLink onClick={props.closeMobileMenu} to="/delivery">{t('delivery')}</NavLink>
                <NavLink onClick={props.closeMobileMenu} to="/about-us">{t('aboutUs')}</NavLink>
            </div>
        </div>
    )
}

export default MobileMenu
