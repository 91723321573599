import React, { useState } from 'react';


let Offer = (props) => {
    return(
        <div className={'container'}>
            <div className={'row'}>
            <div>

<p><b><span
 >ПУБЛІЧНИЙ
ДОГОВІР (ОФЕРТА)</span></b></p>

<p  ><span
 >Цей Договір є
публічним договором в розумінні статті 633 Цивільного кодексу України та являє
собою публічну пропозицію (оферту) веб-сайту </span><span ><a
href="https://monopizza.com.ua/"><span >https://monopizza.com.ua</span></a></span><span
 > (надалі –
«Продавець») будь-якій фізичній особі (далі – «Покупець») надання послуг щодо
замовлення, придбання та доставки товарів через веб-сайт </span><span ><a
href="https://monopizza.com.ua/"><span >https://monopizza.com.ua</span></a></span><span
 >. Акцептування
даного Договору (прийняття умов пропозиції) відбувається шляхом оформлення та
підтвердження Покупцем замовлення.</span></p>

<p  ><b><span
 >ТЕРМІНИ,
ПОНЯТТЯ ТА ВИЗНАЧЕННЯ</span></b></p>

<p  ><span
 >В цьому
Договорі нижченаведені терміни, поняття та визначення мають та вживаються у
такому значенні:</span></p>

<p  ><b><span
 >Акцептування</span></b><span
 > – повне,
безумовне та беззастережне прийняття Покупцем умов даного Договору (публічної
оферти) та правил надання послуг, що вважається наданням згоди на укладення
цього Договору.</span></p>

<p  ><b><span
 >Товар</span></b><span
 > – асортимент
продукції, що пропонується до продажу Покупцеві та представлений на Веб-сайті.</span></p>

<p  ><b><span
 >Послуги</span></b><span
 > – певна дія
або сукупність дій Продавця щодо приймання замовлення, продажу та доставки
товарів замовлених через веб-сайт </span><span ><a
href="https://monopizza.com.ua/"><span >https://monopizza.com.ua</span></a></span><span
 >.</span></p>

<p  ><b><span
 >Веб-сайт</span></b><span
 > – веб-сторінка
в мережі Інтернет за адресою </span><span ><a
href="https://monopizza.com.ua"><span >https://monopizza.com.ua</span></a></span><span
 >, що є джерелом
інформування Покупця щодо пропонованих до продажу товарів, умов оплати та
доставки.</span></p>

<p  ><b><span
 >Замовлення</span></b><span
 > – електронна
заявка Покупця на придбання окремих позицій товару з представленого на веб-сайті
асортименту.</span></p>

<p  ><b><span  >1.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span  >ЗАГАЛЬНІ ПОЛОЖЕННЯ</span></b></p>

<p  ><span  >1.1.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span  >Цей Договір є публічною офертою, яка вступає в силу шляхом
прийняття умов Договору без підписання його сторонами. Цей Договір має юридичну
силу та є рівносильним договору, підписаному сторонами.</span></p>

<p  ><span  >1.2.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span  >Оформленням на Веб-сайті замовлення Покупець підтверджує, що
ознайомився з умовами цього Договору та правилами надання послуг (зокрема щодо
умов оплати та доставки товару), погоджується з ними без будь-яких винятків, та
підтверджує, що:</span></p>

<p  ><em><span>-<span
>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></em><em><span  >йому зрозумілі
всі права та обов'язки по наданні послуг через веб-сайт;</span></em></p>

<p  ><em><span  >-<span
>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></em><em><span  >він є цивільно
дієздатним;</span></em></p>

<p  ><em><span  >-<span
>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></em><em><span  >до оформлення
замовлення він ознайомився зі складом інгредієнтів товару та не має будь-яких алергічних
реакцій чи протипоказань щодо обраних товарів (їх складників);</span></em></p>

<p  ><em><span  >-<span
>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></em><em><span  >він дає дозвіл
на збір, обробку та передачу персональних даних на умовах, визначених  умовами
даного Договору, дозвіл на обробку персональних даних діє протягом всього
строку дії Договору, а також протягом необмеженого терміну після закінчення
його дії;</span></em></p>

<p  ><em><span  >-<span
>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></em><em><span  >повідомляючи
Продавцеві свій </span></em><em><span >e</span></em><em><span
 >-</span></em><em><span >mail</span></em><em><span  > та/або
номер телефону, Покупець дає згоду на використання зазначених засобів зв’язку Продавцем,
а також третіми особами, що залучаються ним для виконання зобов’язань перед
Покупцями, з метою здійснення розсилок рекламного та інформаційного характеру.</span></em></p>

<p  ><span  >1.3.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><em><span  >Всі умови даного
Договору є обов'язковими. </span></em><span  >Перед
початком користування послугами Продавця Покупець зобов'язаний ознайомитися з
умовами даного Договору.<em><span > Якщо Покупець не
згоден з цим Договором повністю або частково (або з будь-яким окремим
положенням) або з правилами надання послуг, він не має права на отримання
послуг Виконавця та зобов’язаний припинити використання веб-сайту.</span></em></span></p>

<p  ><span  >1.4.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span  >Продавець підтверджує, що він надає послуги необмеженому колу Покупців,
і не обмежує останніх у доступі до Веб-сайту.</span></p>

<p  ><b><span  >2.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span  >ПРЕДМЕТ ДОГОВОРУ</span></b></p>

<p  ><span  >2.1.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span  >Продавець за умовами цього Договору зобов’язується надавати
послуги Покупцям щодо приймання замовлення через Веб-сайт, організації продажу
та доставки товарів згідно оформлених замовлень, а Покупець зобов’язується
оплатити вартість послуг.</span></p>

<p  ><span  >2.2.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span  >Під час оформлення замовлення, Покупець на власний розсуд здійснює
вибір товару, який він бажає отримати, визначає його кількість та розмір. Продавець
жодним чином не впливає на вибір Покупця.</span></p>

<p  ><b><span
 >3. ОФОРМЛЕННЯ
ЗАМОВЛЕННЯ</span></b></p>

<p  ><span
 >3.1. Замовлення
Товару здійснюється Покупцем шляхом розміщення замовлення на Сайті.</span></p>

<p  ><span
 >3.2. Під час
розміщення Замовлення Покупець зобов’язаний надати Продавцю наступну
інформацію: прізвище та ім’я особи, що здійснює Замовлення Товару,  контактний
телефон особи, що повинна отримати Товар, точну адресу, яка дає можливість
ідентифікувати місце доставки Товару, найменування, кількість та ціна Товарів.</span></p>

<p  ><span
 >3.3. Всі
зображення продукції та інші інформаційні матеріали, розміщені на Сайті, носять
рекламний характер, і можуть не у повній мірі передавати інформацію про певні
властивості і характеристики Товару, зокрема, але не виключно про ціну Товару,
його колір, форму, розмір, вагу та упаковку. У разі виникнення у Покупця
питань, що стосуються властивостей і характеристик Товару, перед оформленням
Замовлення Покупець може звернутися до Продавця для отримання необхідної йому
інформації про Товар за телефоном, вказаним на Сайті.</span></p>

<p  ><b><span
 >4. ВАРТІСТЬ ТА
УМОВИ ДОСТАВКИ ЗАМОВЛЕННЯ</span></b></p>

<p  ><span
 >4.1. Вартість
та умови доставки Замовлення розміщенні на Сайті. Перед здійсненням Замовлення
Покупець зобов’язаний уточнити вартість і умови його доставки, шляхом
ознайомлення з відповідною інформацією на Сайті, або за телефонами розміщеними
на Сайті.</span></p>

<p  ><span
 >4.2. Продавець
зобов’язується виконати Замовлення протягом 90 хвилин з моменту надходження
Замовлення (Акцепту) від Покупця. Термін доставки Замовлення залежить від
наявності замовлених позицій Товару у Продавця, і часу, необхідного на обробку
Замовлення. Термін доставки Замовлення у виняткових випадках може бути
обговорений з Покупцем індивідуально в залежності від характеристик та
кількості позицій замовленого Товару. У разі відсутності у Продавця частини
позицій замовленого Товару, у тому числі з незалежних від Продавця причин,
останній має право виключити такі позиції Товару із Замовлення Покупця.</span></p>

<p  ><span
 >4.3. Терміни
доставки Замовлення можуть бути збільшені у зв’язку із наявністю обставин
непереборної сили (незадовільні погодні умови, затори на дорогах,
дорожньо-транспортні пригоди тощо).</span></p>

<p  ><span
 >4.4. При
здійсненні доставки Замовлення передається Покупцю або вказаній ним при
оформленні Замовлення особі (Одержувач), а також іншій особі, яка надасть
відомості про Замовлення (ПІБ одержувача та/або номер Замовлення) та здійснить
повну оплату вартості Замовлення кур’єру Продавця (у разі готівкового способу
розрахунків).</span></p>

<p  ><span
 >4.5. Замовлення
вважається виконаним з моменту його передачі Покупцю або вказаній ним особі, на
підтвердження чого отримувачу Замовлення кур’єром Продавця видається відповідний
документ.</span></p>

<p  ><span
 >4.6. У разі
надання Покупцем недостовірної інформації про його контактні дані або про склад
Замовлення, Продавець не несе відповідальності за неналежне виконання
Замовлення.</span></p>

<p  ><b><span
 >5. ПОРЯДОК
РОЗРАХУНКІВ ТА ВАРТІСТЬ ТОВАРУ</span></b></p>

<p  ><span
 >5.1. Відповідно
до умов цього Договору, Покупець має право вибрати один із двох варіантів
оплати: готівковий розрахунок безпосередньо при отриманні Замовлення,
безготівковий розрахунок банківської картою.</span></p>

<p  ><span
 >5.2. На Покупця
також можуть бути покладені комісійні витрати та інші визначені банком витрати,
що можуть бути додані до вартості оформлення Замовлення у разі сплати вартості
Замовлення у безготівковій формі.</span></p>

<p  ><span
 >5.3. Всі
розрахунки між Продавцем та Покупцем здійснюються в гривні.</span></p>

<p  ><span
 >5.4. Ціни на
кожну позицію Товару на Сайті вказані у гривнях.</span></p>

<p  ><span
 >5.5. Продавець
залишає за собою право в односторонньому порядку змінювати ціни на будь-які
позиції Товару, відображені на Сайті. Ціна Товару після здійснення Покупцем
Замовлення не може бути змінена.</span></p>

<p  ><span
 >5.5. Продавець
має право запроваджувати та використовувати програму нарахування бонусів,
проводити акції та вручати подарунки. Види бонусів, акцій та подарунків,
порядок та умови їх застосування чи надання, розмішені на Сайті, та можуть бути
переглянуті Продавцем в односторонньому порядку.</span></p>

<p  ><b><span
 >6. ПРАВА І
ОБОВ’ЯЗКИ СТОРІН</span></b></p>

<p  ><span
 >6.1. Покупець
має право:</span></p>

<p  ><span
 >- отримати
послуги відповідно до вказаного Договору та в рамках оплаченого замовлення.</span></p>

<p  ><span
 >6.2. Покупець
зобов’язаний:</span></p>

<p  ><span
 >- надати
достовірну інформацію під час оформлення Замовлення;</span></p>

<p  ><span
 >- оплатити
Замовлення в обраний ним спосіб.</span></p>

<p  ><span
 >6.3. Продавець
зобов’язаний:</span></p>

<p  ><span
 >- вчасно та в
повному обсязі виконати замовлення Покупця відповідно до його Замовлення.</span></p>

<p  ><b><span
 >7. ЗГОДА НА
ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ</span></b></p>

<p  ><span
 >7.1. Здійснюючи
оформлення Замовлення на сайті, Покупець повідомляє Продавцеві власні
персональні дані, в тому числі однак не обмежуючись: номер телефону, прізвище
ім’я та по батькові, адреса доставки Товару та інше.</span></p>

<p  ><span
 >7.2.
Повідомляючи Продавцю свої персональні дані Покупець надає згоду відповідно до
положень Закону України «Про захист персональних даних» на використання Продавцем
отриманих даних за допомогою автоматизованих систем управління базами даних, а
також інших програмних засобів, спеціально розроблених за дорученням Продавця,
у тому числі, але не обмежуючись, для:</span></p>

<p  ><span
 >- передачі їх
третій стороні, яка тим чи іншим чином пов’язана з цим Договором, або з метою
його повного виконання;</span></p>

<p  ><span
 >- додаткового
інформування Покупця, про статус замовлення;</span></p>

<p  ><span
 >- подальшої
обробки замовлень Покупця;</span></p>

<p  ><span
 >- отриманні
сервісних повідомлень, у тому числі щодо акцій, бонусних програм і знижок.</span></p>

<p  ><span
 >7.3. Продавець
зобов’язується не розголошувати отримані від Покупця персональні дані третім
особам, які не мають відношення до виконання Замовлення та/або до діяльності
Продавця, пов’язаної із наданням Послуг.</span></p>

<p  ><b><span
 >8.
ВІДПОВІДАЛЬНІСТЬ СТОРІН</span></b></p>

<p  ><span
 >8.1. У разі
невиконання або неналежного виконання однією із Сторін зобов’язань за
Договором, винна Сторона зобов’язана відшкодувати протилежній Стороні заподіяні
і доведені такими діями збитки відповідно до чинного законодавства та умов
даного Договору.</span></p>

<p  ><span
 >8.2. Всі
суперечки, що виникають між Сторонами при виконанні цього Договору, вирішуються
шляхом переговорів, а в разі недосягнення згоди між Сторонами спір
розглядається в судовому порядку відповідно до чинного законодавства та умов
даного Договору.</span></p>

<p  ><b><span
 >9. ПРИКІНЦЕВІ
ПОЛОЖЕННЯ</span></b></p>

<p  ><span
 >9.1. Вказаний
договір є чинним із моменту отримання оформлення замовлення Покупцем за
допомогою Сайту до моменту надання Послуги.</span></p>

<p  ><span
 >9.2. Усі
правовідносини, що виникають з цього Договору або пов'язані із ним, у тому
числі пов'язані із дійсністю, укладенням, виконанням, зміною та припиненням
цього Договору, тлумаченням його умов, визначенням наслідків недійсності або
порушення Договору, регламентуються цим Договором та відповідними нормами
чинного в Україні законодавства, а також застосовними до таких правовідносин
звичаями ділового обороту на підставі принципів добросовісності, розумності та
справедливості.</span></p>

<p  ><span
 >&nbsp;</span></p>

</div>

            </div>
        </div>
    )
}



export default Offer;