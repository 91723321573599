import React from 'react'
import { useTranslation } from "react-i18next";
import s from './numbers.module.css';
import close from '../../../../assets/close.svg';

const MobileNumbers = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={s.menuWrapper}>
            <div className={s.info}>
                <div onClick={props.closeMobileNumbers} className={s.close}><img src={close} alt="close" /></div>
                <a onClick={props.closeMobileNumbers} href="tel:+380962109095" className="phone binct-phone-number-1">096 210 90 95</a><br />
                <a onClick={props.closeMobileNumbers} href="tel:+380509555249" className="phone binct-phone-number-1">050 955 52 49</a>
            </div>
        </div>
    )
}

export default MobileNumbers;
