import React from 'react';
import { useSelector } from 'react-redux';

import s from './delivery.module.css';
import { useTranslation } from "react-i18next";

const Delivery = () => {
    const { t, i18n } = useTranslation(); 
    const defaultState = useSelector(state => state.menu.cities);
    const activeCity = useSelector(state => state.menu.activeCity);
    const cityId = useSelector(state => state.menu.activeCity.id);
    const cities = useSelector(state => state.menu.data ? state.menu.data.settings.City : null);
    const city = cities ? cities.find(t => t.spot_id === cityId) : null;
    const cityMap = defaultState.find(t => t.id === activeCity.id).map;

    let begin = '00:00';
    let end = '00:00';

    if (city) {
        begin = city.workingHours[0].begin;
        end = city.workingHours[0].end;
    }

    return(
        <div className={s.delivery}>
        <div className="container">
                <div className="row">
                    <h3>{t('zones')}</h3>
                </div>
        </div>
        <div>
                <div className="row">
                    <div className={s.container_wrap}>
                        <div className={s.delivery_title}>Вартість доставки</div>
                        <div className={s.delivery_content}>Вартість доставки 50 грн в межах міста, при замовленні від 500 грн доставка безкоштовна.</div>
                    </div>
                    <div className={s.container_wrap}>
                        <div className={s.delivery_title}>Час доставки</div>
                        <div className={s.delivery_content}>Час доставки замовлення з {begin}-{end}</div>
                    </div>
                </div>
        </div>
        <div className={s.pickup}>
            <div className="container">
                <div className="row">
                    {city && (
                        <div>
                            <div className={s.pickup_title}>
                                {t('points')}
                            </div>
                            {city.deliveryTerminals.map(terminal => {
                                return (
                                    <div className={s.pickup_info}>
                                        <span>м.{city.name}, {terminal.address}</span>
                                        <div className={s.time}>{begin}-{end}</div>
                                    </div>
                                )
                            })}
                            <div className={s.zones}>
                                <div className={s.pickup_title}>{t('deliveryAreas')}</div>
                                <iframe src={cityMap} width="100%" height="450"></iframe>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
    )
}

export default Delivery;