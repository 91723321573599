import React from "react";
import { useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import s from "./breadcrumbs.module.css";
import Arrow from "../../assets/breadCrumbsArrow.svg";

const BreadCrumbs = (props) => {
    const categories = useSelector((state) => state.menu.categories);
    const products = useSelector((state) => state.menu.products);
    let pathname = props.location.pathname;
    pathname =
        pathname[pathname.length - 1] === "/"
            ? pathname.slice(0, pathname.length - 1)
            : pathname;
    let pathSections = pathname.split("/").filter((name) => name !== "/");
    pathSections =
        pathSections.length === 1 && pathSections[0] === "" ? [] : pathSections;
    console.log("pathSections", pathSections);

    const makeBreadCrumbsMenu = (path) => {
        switch (path) {
            case "":
                return { url: "/", name: "Головна" };
            case "menu":
                return { url: "/menu/", name: "Меню" };
            case "delivery":
                return { url: "/delivery", name: "Доставка" };
            case "basket":
                return { url: "/basket", name: "Кошик" };
            case "about-us":
                return { url: "/about-us", name: "Про нас" };
            case "product":
                const product = products.find(
                    (t) => t.id === pathname.split("/menu/product/")[1]
                );
                const category = categories.find(
                    (t) => t.id === product.parentGroup
                );
                const categoryName = category ? category.name : "";
                const categoryId = category ? category.id : "";

                return { url: `/menu/${categoryId}`, name: categoryName };
            case categories.find((c) => c.id === path) &&
                categories.find((c) => c.id === path).id:
                return {
                    url: "/menu/" + path,
                    name: categories.find((c) => c.id === path).name[props.language],
                };
            case products.find((s) => s.id === path) &&
                products.find((s) => s.id === path).id:
                return {
                    url: "/menu/product/" + path,
                    name: products.find((s) => s.id === path).name[props.language],
                };
            default:
                return { url: "", name: "" };
        }
    };

    return (
        <div className={s.container}>
            <ul className={s.breadCrumbs}>
                {pathSections.map((path, index) => {
                    const { url, name } = makeBreadCrumbsMenu(path);
                    return (
                        <li className={s.breadCrumbsItem} key={index}>
                            <Link className={s.link} to={url}>
                                {name}
                            </Link>
                            <img className={s.arrow} src={Arrow} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default withRouter(BreadCrumbs);
