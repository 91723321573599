import React from 'react';
import s from './preloader.module.css';

const PreloaderFull = () => {
    return(
        <div className={s.overlay}>
            <div className={s.modal}>
                <div className={s.preloader}></div>
            </div>
        </div>
    )
}

export default PreloaderFull;