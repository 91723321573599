import React from "react";
import { withRouter } from "react-router-dom";

import Slider from "../../components/slider/slider";
import Delivery from "./delivery/delivery";
import Products from "./products/products";

import Scroll from "react-scroll";

var Element = Scroll.Element;

const Main = (props) => {
	const city = props.cities.find((s) => s.name === props.activeCity.name);
	const streetAddress = [];

	city.terminals.forEach((terminal) => streetAddress.push(terminal.name));

	return (
		<>
			<Slider
				activeCity={props.activeCity}
				slider={props.slider ? props.slider : []}
			/>
			<Element name="menu">
				<Products
					categories={props.categories}
					languageMenu={props.languageMenu}
					products={props.products}
					activeCategory={props.activeCategory}
					updateCategory={props.updateCategory}
					updateProduct={props.updateProduct}
					cities={props.cities}
					activeCity={props.activeCity}
					hideProducts={props.hideProducts}
				/>
			</Element>
			<Element name="delivery">
				<Delivery cities={props.cities} activeCity={props.activeCity} />
			</Element>
		</>
	);
};

export default withRouter(Main);
