import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter, Redirect } from "react-router";
import { useTranslation } from "react-i18next";

import Button from '../../components/button';

import s from './style.module.css';

const Success = (props) => {
    const { t, i18n } = useTranslation();

       return(
        <div className={s.container}>
            <div className={s.content}>
            <div className={s.row}>
            <img className={s.logo} src={require('../../assets/logo.png')}/>
            <h1>ЗМІНИТЬ ВАШЕ УЯВЛЕННЯ ПРО ПІЦУ!</h1>
            <h3 className={s.title}>Замовлення №{props.match.params.order} майже почали готувати наші вправні піцейоло.</h3>
            <p className={s.description}>
                Зараз тільки оператор підтвердить його.<br/> 
                Ми зателефонуємо вам за номером <br/> 
                {props.match.params.phone} за 10 хвилин. <br/> 
                Дякуємо, що обрали нашу піцу! <br/> 
                Впевнені, її смак вас здивує та зігріє… <br/> 
                Смачного!</p>
            <div className={s.socialContainer}>
                <a target="_blank" href="https://www.facebook.com/mirafoodsmarket" className={s.social}>
                    <img src={require('../../assets/facebook.svg')}/>
                </a>
                <a target="_blank" href="https://www.instagram.com/mirafoodsmarket/" className={s.social}>
                    <img src={require('../../assets/instagram.svg')}/>
                </a>
            </div>
            <div className={s.bt}>
            <a href='tel:+380934457066' className={s.button}>
                <p>Якщо є питання, телефонуйте</p>
                {/* <span>0 800 33 00 53</span> */}
                <span class="binct-phone-number-1">+38 (093) 445 70 66</span>
            </a>
           {/**<Button title={t('main')} 
                        handleClick={() => props.history.push('/')}/> */}
            </div>
            </div>
            </div>
        </div>
       )
    
}


export default withRouter(Success);